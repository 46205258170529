<template>
  <div class="staff">
    <div class="staff-banner">
      <div class="handle-btn">
        <div class="text-con">
          <p>企业培训服务依托于伟东云职业教育服务平台，整合伟东云教育现有优质师资力量和教育资源，为客户提供从需求分析、方案设计到项目实施及落地的一站式解决方案；包括平台搭建、使用指南制定、内容设计生产、业务流程优化、项目执行管理、数据分析、知识管理、社群运营等服务，助力客户实现数字化企业转型。</p>
          <p>我们定位为面向企业的培训解决方案服务商，致力于提供设计、实施、评估一体化的解决方案，帮助企业建立起连接业务目标的培训体系，实现人才战略与商业价值的有效结合。</p>
        </div>
        <button @click="toLogin">企业管理人员注册/登录</button>
        <button @click="goAdmin">企业管理人员试用</button>
      </div>
    </div>
    <div class="staff-content">
      <div class="staff-content-container">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/enter1.png"> 
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/enter2.png"> 
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/enter3.png"> 
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/enter4.png" class="last"> 
      </div>
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/staff-bg.png" class="bg"> 
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="640px"
      :before-close="handleClose">
        <div class="loginDialog"><fe-login-register activeName="ldt" :tabsList="tabsList"></fe-login-register></div>
    </el-dialog>
  </div>
</template>
<script>
import feLoginRegister from '@/modules/entrance/components/fe-login-register'
import {jumpTry} from '@/utils/getBranchDomain.js'

export default {
  data(){
    return{
      dialogVisible:false,
      tabsList: [
        { key: "ldt", val: "我是企业管理者" },
      ]
    }
  },
  components:{
    feLoginRegister
  },
  methods:{
    toView(){
      window.open('https://dxpx.wdeduc.com/','_blank');
    },
    toJump(path){
      this.$router.push({
        path:path
      })
    },
    handleClose(){
      this.dialogVisible = false;
    },
    toLogin(){
      this.dialogVisible = true;
    },
    goAdmin(){
      jumpTry('ldt');
    },
  }
}
</script>
<style lang="scss" scoped>
.staff{
  min-width:1360px;
}
.staff-content{
  background: linear-gradient(to bottom, #fff, #FFCAC1);
  img{
    display:block;
    width:1200px;
    margin: 24px auto 24px;
  }
}
.staff-banner{
  position:relative;
  background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/enter-bg.png') no-repeat center center;
  background-size:cover;
  padding-top:180px;
  padding-bottom:110px;
  .handle-btn{
    // bottom:110px;
    // position:absolute;
    // left:50%;
    width:1200px;
    margin:0 auto;
    text-align:center;
    // transform:translateX(-50%);
    P{
      color:#fff;
      font-size:20px;
      font-weight:500;
      line-height:32px;
      color:#FDDCB8;
      text-indent:44px;
      text-align:left;
    }
    .text-con{
      margin-bottom:30px;
    }
      button{
        background: linear-gradient( 180deg, #FFF6DF 2%, #F5B957 100%);
        width:308px;
        text-align:center;
        height:54px;
        line-height:54px;
        color:#fff;
        display:inlnie-block;
        border:none;
        outline:none;
        font-size:18px;
        font-weight:600;
        border-radius:32px;
        color:$WDPrimaryColor;
        margin:0 auto;
        transition: all .6s ease;
        margin: 0 65px;
        cursor:pointer;
        &:hover{
          opacity:.8;
        }
    }
  }
}
::v-deep .el-dialog__header{
  padding:0;
}
::v-deep .el-dialog{
  border-radius:16px;
}
.loginDialog{
  ::v-deep button{
    margin: 0 auto 36px;
    display:block;
  }
}
.staff-content{
  background:linear-gradient(to bottom, #fff, #FFCAC1);
  position: relative;
  overflow: hidden;
  .bg{
    width:100%;
    position:relative;
    z-index:2;
    margin-top:-300px;
    margin-bottom:0;
  }
  .last{
    position:relative;
    z-index:3;
  }
  .staff-content-container{
    width:1220px;
    margin:0 auto;
    z-index:3;
    position:relative;
  }
}
</style>

